<script lang="ts">
	import PleaseWaitSpinner from "./PleaseWaitSpinner.svelte";

    export let label: string | undefined = undefined;
    export let on_click = async () => {};
    export let submit: boolean = false;
    export let form_action: string | undefined = undefined;
    export let enabled: boolean = true;
    
    let class_override = $$props.class;

    /**
     * This will replace the button contents with a spinner and disable the button while the on_click function is running.
     */
    export let enable_spinner: boolean = false;
    export let spinner_class_override: string = "";
    export let spinner_color: 'primary' | 'inverse' = 'inverse';
    export let spinner_message: string = '';

    /**
     * Force the spinner to be shown. This is useful if you want to control the state of the spinner externally through a bind operation.
     */
    export let force_spinner: boolean = false;

    let processing = false;
    function process() {

        processing = true;
        on_click().then(() => {processing = false;});
    }

</script>

<button type={submit ? "submit" : "button"} 
        on:click={process} 
        class="{enabled ? 'bg-primary-enabled text-white focus:outline-none focus:ring-2 focus:ring-primary-enabled focus:ring-offset-2': 'bg-primary-disabled text-primary-foreground-disabled'} inline-flex items-center rounded-md border border-transparent px-3 sm:px-4 py-2 text-sm font-medium shadow-sm {class_override}"
        disabled={processing || !enabled}
        formaction={form_action}>


    {#if (enable_spinner && processing) || force_spinner}
        <PleaseWaitSpinner class={spinner_class_override} {spinner_color} message={spinner_message}/>
    {:else}
        <slot></slot>
        {#if label}
            {label}
        {/if}
    {/if}
</button>
